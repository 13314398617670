import React from "react";

// Component imports
import PainLoggerLayout from "../../components/pain_logger/PainLoggerLayout";
import Construction from "../../components/common/Construction";
import SEO from "../../components/common/seo";

// Image imports

// CSS imports

export default ({ data }) => {
  return (
    <PainLoggerLayout>
      <SEO title="Pain Logger Privacy" keywords={[`pain`, `painlogger`, `privacy`]} />
      <Construction
        title="Pain Logger Privacy Policy"
        backPage="pain_logger/pain_logger"
        backButtonTitle="Back"
      />
    </PainLoggerLayout>
  );
};
